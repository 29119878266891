/*global jQuery, window */
(function ($, window, undefined) {
    "use strict";

    var choixContraintes = {},
        produits,
        options;

    window.initProduitOptions = function (data) {
        produits = data.produits;
        options = data.options;

        initContraintes();
    };

    function initContraintes() {
        // Retire tous les labels et réaffiche tous les dropdownlists
        $(".produit-option-selected").each(function () {
            $(this).remove();
        });

        $(".produit-options-select-option").each(function () {
            $(this).removeClass("produit-option-desactivee")
                .prop("disabled", false)
                .parent().show();
            $("#produit-no-selection").text("-");
            $("#produit-prix").text("-");
        });

        $(".produit-options-select").each(function () {
            $(this).val('placeholder');
        });

        for (var i = 0; i < options.length; i++) {
            choixContraintes[options[i]] = null;
        }
    }

    $("#produit-panier-reset").click(initContraintes);

    $("select.produit-options-select").change(function () {
        var $selectChange = $(this),
            choixId = $selectChange.children('option:selected').val(),
            caractId = $selectChange.attr("name").substring(4);

        // Modification du tableau de contraintes
        choixContraintes[caractId] = choixId == "placeholder" ? null : choixId;

        var produitsPossibles,
            choixPossibles;

        // Parcours des selects pour définir les possibilitées et désactiver les options non compatibles
        $(".produit-options-select-option").each(function () {
            // Tableau des produits possibles créé selon les containtes
            // (en excluant la contraintre propre à la caractéristique)
            var	valide, o;
            produitsPossibles = [];

            for (var i = 0; i < produits.length; i++) {
                valide = true;
                for (o in options) {
                    if (produits[i][options[o]] != choixContraintes[options[o]] &&
                        choixContraintes[options[o]] !== null &&
                        $(this).parent().attr("name").substring(4) != options[o]
                    ) {
                        valide = false;
                    }
                }
                if (valide) {
                    produitsPossibles.push(produits[i]);
                }
            }

            // Tableau des choix possibles pour chaque options
            choixPossibles = [];
            for (var pp in produitsPossibles) {
                for (o in options) {
                    if (options.hasOwnProperty(o)) {
                        if (!choixPossibles[options[o]]) {
                            choixPossibles[options[o]] = [];
                        }
                        if ($.inArray(produitsPossibles[pp][options[o]], choixPossibles[options[o]]) == -1) {
                            choixPossibles[options[o]].push(produitsPossibles[pp][options[o]]);
                        }
                    }
                }
            }

            // Désactivation des produits qui ne sont pas dans le tableau
            var choix = parseInt($(this).val()),
                choixPossiblesIndex = choixPossibles[$(this).parent().attr("name").substring(4)];

            if ($.inArray(choix, choixPossiblesIndex) == -1) {
                $(this).addClass("produit-option-desactivee");
                $(this).prop("disabled", true);
            } else {
                $(this).removeClass("produit-option-desactivee");
                $(this).prop("disabled", false);
            }
        });

        // Affichage du code de produit
        var tousSelect = true;
        $("#produit-form select option:selected").each(function () {
            if ($(this).val() == "placeholder") {
                tousSelect = false;
            }
        });
        produitsPossibles = [];

        // Ajout des produits si toutes les contraintes sont respectées
        for (var i = 0; i < produits.length; i++) {
            var valide = true;
            for (var o in options) {
                if (produits[i][options[o]] != choixContraintes[options[o]] && choixContraintes[options[o]] !== null) {
                    valide = false;
                }
            }
            if (valide) {
                produitsPossibles.push(produits[i]);
            }
        }
        $("#produit-no-selection").text((produitsPossibles.length == 1 && tousSelect) ? produitsPossibles[0].no : "-");
        $("#produit-prix").text((produitsPossibles.length == 1 && tousSelect) ? produitsPossibles[0].prix + ' $' : "-");

        // Transformation du DropDownList en label et ajout d'un X
        if ($selectChange.val() != "placeholder") {
            var x = $('<span></span>').addClass('option-remove font-icon icon-remove')
                .click(function () {
                    $(this).parent().remove();
                    $selectChange.val("placeholder").show().change();
                });

            var label = $('<span></span>').addClass('produit-option-selected')
                .text($selectChange.children('option:selected').text())
                .append(x);

            $selectChange.after(label).hide();
        }
    });

    // Onglets : Description | Garantie
    $("#produit-onglets a").click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass("produit-onglet-actif")) {
            $("#produit-onglets a").toggleClass("produit-onglet-actif");
            $("#produit-onglets-contenu > div").toggle();
        }

    });

    // Validation de l'ajout au projet
    $("#produit-form").submit(function (e) {
        var optionsValide = true,
            inputQuantite = $("#produit-panier-number").val();

        if (isNaN(inputQuantite) || inputQuantite < 1 || inputQuantite % 1 !== 0) {
            optionsValide = false;
            $("#produit-panier-number").addClass("invalid");
        } else {
            $("#produit-panier-number").removeClass("invalid");
        }

        $("#produit-form select").each(function () {
            if ($(this).val() == "placeholder") {
                optionsValide = false;
                $(this).addClass("invalid");
            } else {
                $(this).removeClass("invalid");
            }
        });

        if (!optionsValide) {
            e.preventDefault();
        }
    });
})(jQuery, window);