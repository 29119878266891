/*jslint browser: true */
/*global $, url_root, locale */

(function () {
    "use strict";

    // Retourne le message d'erreur
    window.boxingMsg = (function (msg) {
            return "<div class='msg-box msg-erreur'>" +
                        "<span class='fa-msg font-icon icon-remove-circle'></span>" +
                        "<p>" + msg + "</p>" +
                    "</div>";
        });

    /*** Affichage du menu Produits et Services ***/
    // Désactivation du lien si le javascript est exécuté
    $(".nav-deroulant > a").click(function (e) { e.preventDefault(); });
    if ('ontouchend' in document.documentElement) {
        $(document).on("touchend", function () {
            $(".nav-deroulant > nav").hide();
            $("#nav-recherche").hide();
        });
        // Événement touch qui affiche le nav
        $(".nav-deroulant").on({
            touchend : function (e) {
                e.stopPropagation();
                $("#nav-recherche").hide();
                if (!$(e.target).is($(".nav-deroulant > nav")) && !$(e.target).is($(".nav-deroulant > nav").find("*"))) {
                    $(".nav-deroulant > nav").each(function (i, el) {
                        if ($(el).is(":visible") && !$(el).parent().find("*").is(e.target))
                            $(el).hide();
                    });
                    $(" > nav", this).toggle();
                }
            }
        });
    }
    $(document).click(function () {
        $(".nav-deroulant > nav").hide();
        $("#nav-recherche").hide();
    });

    var tShow, tHide;
    // Événement hover qui affiche le nav
    $(".nav-deroulant").on({
        click : function (e) {
            e.stopPropagation();
            $("#nav-recherche").hide();
        },
        mouseenter : function () {
            var target = this;
            clearTimeout(tHide);
            tShow = setTimeout((function() {
                $(".nav-deroulant > nav").hide();
                $("> nav", target).show();
            }), 100);
        },
        mouseleave : function () {
            clearTimeout(tShow);
            tHide = setTimeout((function() {
                $(".nav-deroulant > nav").hide();

                $("#nav-produits-nav").find("li.active").removeClass("active");
                $("#nav-produits-nav").find("li:first").addClass("active");

                $("#nav-produits-content > div.active").removeClass("active");
                $("#nav-produits-content > div:first").addClass("active");
            }), 250);
        }
    });

    // Menu des produits dans le nav-produits
    $("#nav-produits-nav ul").menuAim({
        activate: function (row) {
            var $row = $(row),
                rowIndex = $row.index() + 1;

            $row.parent().find("li.active").removeClass("active");
            $row.addClass("active");

            $("#nav-produits-content > div.active").removeClass("active");
            $("#nav-produits-content > div:nth-child(" + rowIndex + ")").addClass("active");
        },
        tolerance: 40,
    });

    /*** Recherche Rapide ***/
    // Debounce pour retarder l'affichage
    function debounce(func, n) {
        var timeout;
        return function () {
            var context = this,
                args = arguments,
                later = function () {
                    timeout = null;
                    func.apply(context, args);
                };
            window.clearTimeout(timeout);
            timeout = window.setTimeout(later, n);
        };
    }

    // Requête Ajax pour la recherche rapide
    function rechercheRapideAjax() {
        // Recherche après le second caractère
        if ($("#champ-recherche").val().length > 1) {
            var input = encodeURIComponent($("#champ-recherche").val());
            $.get(url_root + "/" + locale + "/recherche-rapide/" + input, function (data) {
                $("#resultat-recherche").html(data.html);
                $("#nav-recherche").show();
            });
        } else {
            $("#nav-recherche").hide();
        }
    }

    // Affichage des résultats
    $("#champ-recherche").keyup(debounce(function (e) {
        if ((e.which < 37 || e.which > 40) && e.which !== 27) {
            rechercheRapideAjax();
        }
        // Afficher les résultats avec la flèche en bas
        else if (e.which === 40 && $("#nav-recherche").is(":hidden") && $("#resultat-recherche li").length > 0) {
            $("#nav-recherche").show();
        }
    }, 250));

    // Submit de la barre de recherche
    $("#form-recherche").submit(function (e) {
        if ($("#champ-recherche").val().trim().length < 2) {
            e.preventDefault();
        }
    });

    // Déplacement dans le résultat
    var next;
    $("#form-recherche").keydown(function (e) {
        var liSelected = $(".recherche-rapide-active");

        // Si la flèche bas est enfoncé
        if (e.which === 40) {
            e.preventDefault(); // empêche la fenêtre de "scroller"
            if (liSelected.length > 0) {
                next = liSelected.next("#form-recherche li");
                if (next.is("li")) {
                    liSelected.removeClass("recherche-rapide-active");
                    liSelected = next.addClass("recherche-rapide-active");
                    liSelected.find("a").focus();
                } else {
                    next = liSelected.parent().next("h5").next("ul").find("li:first-child");
                    if (next.is("li")) {
                        liSelected.removeClass("recherche-rapide-active");
                        liSelected = next.addClass("recherche-rapide-active");
                        liSelected.find("a").focus();
                    }
                }
            } else {
                liSelected = $(".recherche-rapide-ul li").eq(0).addClass("recherche-rapide-active");
                liSelected.find("a").focus();
            }
        }

        // Si la touche flèche haut est enfoncée
        if (e.which === 38) {
            e.preventDefault(); // empêche la fenêtre de "scroller"
            if (liSelected.length > 0) {
                next = liSelected.prev(".recherche-rapide-ul li");
                if (next.is("li")) {
                    liSelected.removeClass("recherche-rapide-active");
                    liSelected = next.addClass("recherche-rapide-active");
                    liSelected.find("a").focus();
                } else {
                    next = liSelected.parent().prev("h5").prev("ul").find("li:last-child");
                    if (next.is("li")) {
                        liSelected.removeClass("recherche-rapide-active");
                        liSelected = next.addClass("recherche-rapide-active");
                        liSelected.find("a").focus();
                    } else {
                        liSelected = $(".recherche-rapide-ul li").eq(0).removeClass("recherche-rapide-active");
                        $("#champ-recherche").focus();
                        $("#nav-recherche").hide();
                    }
                }
            }
        }

        // Si la touche Escape est enfoncée
        if (e.which === 27) {
            $("#form-recherche").blur();
            $("#nav-recherche").hide();
        }
    });

    // Empêche la fermeture du nav de résultats
    $("#form-recherche").click(function (e) {
        e.stopPropagation();
    });

    // Modification de la province -> mise à jour du pays
    $("#province").change(function () {
        var id = $(this).find(":selected").parent().attr("id");
        if (id == "prov-ca")
            $("#pays").val(1);
        else if (id == "prov-us")
            $("#pays").val(2);
    });
    // Modification du pays -> mise à jour des provinces
    $("#pays").change(function () {
        var id = $(this).find(":selected").val();
        if (id == 1) {
            if ($("#province").find(":selected").parent().attr("id") == "prov-us") {
                $("#province").val("placeholder");
            }
            $("#province").find("#prov-us").prop("disabled", true);
            $("#province").find("#prov-ca").prop("disabled", false);

        } else if (id == 2) {
            if ($("#province").find(":selected").parent().attr("id") == "prov-ca") {
                $("#province").val("placeholder");
            }
            $("#province").find("#prov-us").prop("disabled", false);
            $("#province").find("#prov-ca").prop("disabled", true);
        }
    });

}());
